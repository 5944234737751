<template>
  <div>
    <e-filters
      ref="filters"
      :title="$t('Filtros')"
      :searching="fetching"
      :search-text="$t('Pesquisar')"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <template #after_buttons>
        <b-dropdown
          right
          variant="primary"
          :disabled="fetching"
          :text="$t('Baixar Relatório')"
          class="ml-1"
        >
          <template #button-content>
            <div style="display: inline-block; width: 25px">
              <b-spinner
                v-if="fetching"
                small
                style="margin-bottom: 1px"
              />
              <b-icon
                v-if="!fetching"
                icon="download"
              />
            </div>
            {{ $t('Baixar Relatório') }}
          </template>
          <b-dropdown-item @click="downloadReport('Sale')">
            {{ $t('Vendáveis') }}
          </b-dropdown-item>
          <b-dropdown-item @click="downloadReport('RentAndLending')">{{
            $t('Ativos')
          }}</b-dropdown-item>
          <b-dropdown-item @click="downloadReport('All')">
            {{ $t('Todos') }}
          </b-dropdown-item>
        </b-dropdown>
        <a
          ref="downloadLink"
          style="display: none"
        />
      </template>
      <b-row>
        <b-col md="4">
          <e-store-combo
            id="store-name"
            v-model="filters.storeId"
            :required="true"
          />
        </b-col>
        <b-col md="4">
          <e-search-sku
            id="sku"
            v-model="filters.skuId"
            :required="false"
            :min-search-length="3"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="report-type"
            v-model="filters.reportType"
            type="vue-select"
            :label="$t('Tipo')"
            :options="reportTypes"
            :placeholder="$t('Selecione')"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="date"
            v-model="filters.date"
            type="datepicker"
            :label="$t('Data')"
            class="required"
            validation="required"
          />
        </b-col>
      </b-row>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <b-table
        ref="tablestockItems"
        responsive
        show-empty
        striped
        class="mb-1 bordered"
        :empty-text="getEmptyTableMessage($tc('STOCK_POSITION.NAME'), 'female')"
        :fields="fields"
        :items="stockItems"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(skuName)="{ item }">
          <e-product-router-link
            :sku-id="item.sku.id"
            :product-id="item.sku.productId"
            :product-name="item.sku.name"
            :ean="item.sku.ean"
          />
        </template>
        <template #cell(stock)="row">
          <e-router-link
            :to="{
              name: 'stock-movement-list',
              query: { storeId: row.item.store.id, skuId: row.item.sku.id },
            }"
            :title="$t('Visualizar movimentações')"
            :value="row.item.stock"
          />
        </template>

        <template #custom-foot>
          <tr>
            <th
              colspan="3"
              class="text-right"
            >
              {{ $t('Total') }}
            </th>
            <th class="text-center">
              {{ stockTotal | number }}
            </th>
            <th class="text-center">
              {{ valueTotal | currency }}
            </th>
          </tr>
        </template>
      </b-table>

      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="user-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BPagination,
  BTable,
  BDropdown,
  BDropdownItem,
  BSpinner,
  BIcon,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  EPageSizeSelector,
  EPaginationSummary,
  EFilters,
  ESearchSku,
  ERouterLink,
  EStoreCombo,
  EProductRouterLink,
} from '@/views/components'
import { formulateValidation } from '@/mixins'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BIcon,
    EPaginationSummary,
    EPageSizeSelector,
    EFilters,
    ESearchSku,
    ERouterLink,
    EStoreCombo,
    EProductRouterLink,
  },

  mixins: [formulateValidation],

  data() {
    return {
      fetching: false,
      saving: false,
    }
  },

  computed: {
    ...mapGetters('app', ['storeOptions']),
    ...mapState('pages/stock/stockRetroactive', [
      'stockItems',
      'reportData',
      'paging',
      'sorting',
      'filters',
      'summaryFields',
    ]),

    fields() {
      return [
        {
          label: this.$t('Último movimento'),
          key: 'lastMovementDate',
          class: 'text-center',
          thStyle: { width: '150px' },
          sortable: true,
          formatter: value => this.$options.filters.datetime(value),
        },
        {
          label: this.$t('Loja'),
          key: 'store',
          tdClass: 'text-left',
          formatter: val => this.$options.filters.storeName(val),
        },
        {
          label: this.$t('Produto'),
          key: 'skuName',
          sortKey: 'sku.name',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          label: this.$t('Estoque'),
          key: 'stock',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
          formatter: value => this.$options.filters.number(value, 3, true),
        },
        {
          label: this.$t('Valor'),
          key: 'value',
          thStyle: { width: '100px' },
          tdClass: 'text-right',
          sortable: true,
          formatter: value => this.$options.filters.currency(value),
        },
      ]
    },

    stockTotal() {
      return this.summaryFields?.stock?.value
    },

    valueTotal() {
      return this.summaryFields?.value?.value
    },

    dateToFormated() {
      return this.$options.filters.date(this.filters.dateTo)
    },
    today() {
      return new Date()
    },

    reportTypes() {
      return [
        { value: 'Sale', label: this.$t('Vendáveis') },
        { value: 'RentAndLending', label: this.$t('Ativos') },
        { value: 'All', label: this.$t('Todos') },
      ]
    },
  },

  mounted() {
    // this.getData()
  },

  methods: {
    ...mapActions('pages/stock/stockRetroactive', [
      'fetchStockItems',
      'generateReport',
      'resetFilters',
      'setCurrentPage',
      'setPageSize',
    ]),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchStockItems()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    async downloadReport(reportType) {
      try {
        if (this.$refs.filters.isValid()) {
          this.fetching = true
          await this.generateReport(reportType)
          // Get the download link element from the template
          const { downloadLink } = this.$refs

          if (this.reportData) {
            // Set the URL and download attributes of the link
            downloadLink.href = this.reportData
            downloadLink.download = `EstoqueRetroativo-Loja_${this.filters.storeId}-Data_${this.filters.date}.xlsx`

            // Click the link to start the download
            downloadLink.click()
          } else {
            this.showError({ message: this.$t('Não foi possível recuperar os dados do relat') })
          }
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },

    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },

    resetFiltersLocal() {
      this.resetFilters()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },
  },
}
</script>

<style lang="scss" scoped></style>
