var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "e-filters",
        {
          ref: "filters",
          attrs: {
            title: _vm.$t("Filtros"),
            searching: _vm.fetching,
            "search-text": _vm.$t("Pesquisar"),
          },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
          scopedSlots: _vm._u([
            {
              key: "after_buttons",
              fn: function () {
                return [
                  _c(
                    "b-dropdown",
                    {
                      staticClass: "ml-1",
                      attrs: {
                        right: "",
                        variant: "primary",
                        disabled: _vm.fetching,
                        text: _vm.$t("Baixar Relatório"),
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "button-content",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    width: "25px",
                                  },
                                },
                                [
                                  _vm.fetching
                                    ? _c("b-spinner", {
                                        staticStyle: { "margin-bottom": "1px" },
                                        attrs: { small: "" },
                                      })
                                    : _vm._e(),
                                  !_vm.fetching
                                    ? _c("b-icon", {
                                        attrs: { icon: "download" },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(
                                " " + _vm._s(_vm.$t("Baixar Relatório")) + " "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c(
                        "b-dropdown-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.downloadReport("Sale")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Vendáveis")) + " ")]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.downloadReport("RentAndLending")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("Ativos")))]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.downloadReport("All")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Todos")) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c("a", {
                    ref: "downloadLink",
                    staticStyle: { display: "none" },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("e-store-combo", {
                    attrs: { id: "store-name", required: true },
                    model: {
                      value: _vm.filters.storeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "storeId", $$v)
                      },
                      expression: "filters.storeId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("e-search-sku", {
                    attrs: {
                      id: "sku",
                      required: false,
                      "min-search-length": 3,
                    },
                    model: {
                      value: _vm.filters.skuId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "skuId", $$v)
                      },
                      expression: "filters.skuId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "report-type",
                      type: "vue-select",
                      label: _vm.$t("Tipo"),
                      options: _vm.reportTypes,
                      placeholder: _vm.$t("Selecione"),
                    },
                    model: {
                      value: _vm.filters.reportType,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "reportType", $$v)
                      },
                      expression: "filters.reportType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    staticClass: "required",
                    attrs: {
                      id: "date",
                      type: "datepicker",
                      label: _vm.$t("Data"),
                      validation: "required",
                    },
                    model: {
                      value: _vm.filters.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "date", $$v)
                      },
                      expression: "filters.date",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.filterData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            ref: "tablestockItems",
            staticClass: "mb-1 bordered",
            attrs: {
              responsive: "",
              "show-empty": "",
              striped: "",
              "empty-text": _vm.getEmptyTableMessage(
                _vm.$tc("STOCK_POSITION.NAME"),
                "female"
              ),
              fields: _vm.fields,
              items: _vm.stockItems,
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "sort-changed": _vm.getData,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(skuName)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c("e-product-router-link", {
                      attrs: {
                        "sku-id": item.sku.id,
                        "product-id": item.sku.productId,
                        "product-name": item.sku.name,
                        ean: item.sku.ean,
                      },
                    }),
                  ]
                },
              },
              {
                key: "cell(stock)",
                fn: function (row) {
                  return [
                    _c("e-router-link", {
                      attrs: {
                        to: {
                          name: "stock-movement-list",
                          query: {
                            storeId: row.item.store.id,
                            skuId: row.item.sku.id,
                          },
                        },
                        title: _vm.$t("Visualizar movimentações"),
                        value: row.item.stock,
                      },
                    }),
                  ]
                },
              },
              {
                key: "custom-foot",
                fn: function () {
                  return [
                    _c("tr", [
                      _c(
                        "th",
                        { staticClass: "text-right", attrs: { colspan: "3" } },
                        [_vm._v(" " + _vm._s(_vm.$t("Total")) + " ")]
                      ),
                      _c("th", { staticClass: "text-center" }, [
                        _vm._v(
                          " " + _vm._s(_vm._f("number")(_vm.stockTotal)) + " "
                        ),
                      ]),
                      _c("th", { staticClass: "text-center" }, [
                        _vm._v(
                          " " + _vm._s(_vm._f("currency")(_vm.valueTotal)) + " "
                        ),
                      ]),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "user-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }